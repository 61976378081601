import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AuthRoutingModule } from './auth-routing.module';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [LoginComponent, ProfileComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    NgxAuthFirebaseUIModule,
    MatCardModule
  ]
})
export class AuthModule {
}
