import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Child, Event, EventType, CleaningType } from '../../interfaces';
import * as firebase from 'firebase';
import { Timestamp } from '@firebase/firestore-types';

@Component({
  selector: 'app-clean',
  templateUrl: './clean.component.html',
  styleUrls: ['./clean.component.css']
})
export class CleanComponent implements OnInit {
  @Input('data') child: Child;
  @Output() accept: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() { }

  ngOnInit() {
  }

  onWetClick() {
    this.emitEvent(CleaningType.Wet);
  }

  onDirtyClick() {
    this.emitEvent(CleaningType.Dirty);
  }

  private emitEvent(cleaningType: CleaningType) {
    const event: Event = { type: EventType.Clean, date: firebase.firestore.FieldValue.serverTimestamp() as Timestamp, cleaningType };
    this.accept.emit(event);
  }
}
