import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Child, Event, EventType, BathingType } from '../../interfaces';
import * as firebase from 'firebase';
import { Timestamp } from '@firebase/firestore-types';

@Component({
  selector: 'app-bath',
  templateUrl: './bath.component.html',
  styleUrls: ['./bath.component.css']
})
export class BathComponent implements OnInit {
  @Input('data') child: Child;
  @Output() accept: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() { }

  ngOnInit() {
  }

  onBatheClick() {
    this.emitEvent(BathingType.Bathe);
  }

  onShowerClick() {
    this.emitEvent(BathingType.Shower);
  }

  private emitEvent(bathingType: BathingType) {
    const event: Event = { type: EventType.Bathe, date: firebase.firestore.FieldValue.serverTimestamp() as Timestamp, bathingType };
    this.accept.emit(event);
  }
}
