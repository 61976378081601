import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ChildFormModule } from './child-form/child-form.module';

@NgModule({
  imports: [
    BrowserModule,
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    BrowserAnimationsModule,
    NgxAuthFirebaseUIModule.forRoot(environment.firebase, () => 'BabyApp', {
      enableFirestoreSync: true,
      toastMessageOnAuthSuccess: true,
      toastMessageOnAuthError: true,
      authGuardFallbackURL: '/login',
      authGuardLoggedInURL: '/dashboard',
    }),
    AppRoutingModule,
    DashboardModule,
    AuthModule,
    ChildFormModule,
    SharedModule
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}
