import { Timestamp } from '@firebase/firestore-types';
import { discardPeriodicTasks } from '@angular/core/testing';

export interface Event {
    uid?: string;
    type: EventType;
    date?: Timestamp;
    amount?: number;
    endDate?: Timestamp;
    bathingType?: BathingType;
    cleaningType?: CleaningType;
}

export enum EventType {
    Eat = 'eat',
    Sleep = 'sleep',
    Bathe = 'bathe',
    Clean = 'clean'
}

export enum BathingType {
    Bathe = 'bathe',
    Shower = 'shower'
}

export enum CleaningType {
    Dirty = 'dirty', // poop
    Wet = 'wet' // pee
}
