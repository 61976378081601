import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { Child, Event, EventType } from '../../interfaces';
import * as firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-sleep',
  templateUrl: './sleep.component.html',
  styleUrls: ['./sleep.component.css']
})
export class SleepComponent implements OnInit, OnDestroy {
  @Input('data') child: Child;
  @Output() accept: EventEmitter<Event> = new EventEmitter<Event>();

  timer;
  counter: number;
  startTime: Date;
  endTime: Date;

  constructor() { }

  ngOnInit() {
    this.counter = 0;
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  onStartClick(): void {
    this.startTime = new Date();
    this.timer = setInterval(() => {
      this.counter = (new Date()).getTime() - this.startTime.getTime();
    }, 1000);
  }
  onStopClick(): void {
    if (this.counter === 0) return;

    this.endTime = new Date();
    this.counter = 0;
    clearInterval(this.timer);

    const event: Event = { type: EventType.Sleep, date: Timestamp.fromDate(this.startTime), endDate: Timestamp.fromDate(this.endTime) };
    this.accept.emit(event);
  }

  elapsedTime() {
    const date = new Date(this.counter);
    return `${('0' + date.getUTCHours()).slice(-2)}:${('0' + date.getUTCMinutes()).slice(-2)}:${('0' + date.getUTCSeconds()).slice(-2)}`;
  }
}
