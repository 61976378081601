import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { User, Child } from '../dashboard/interfaces';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import * as firebase from 'firebase';
import { Router } from '@angular/router';

@Component({
  selector: 'app-child-form',
  templateUrl: './child-form.component.html',
  styleUrls: ['./child-form.component.css']
})
export class ChildFormComponent implements OnInit {

  form: FormGroup;
  users$: Observable<User[]>;
  currentUser: firebase.User;

  constructor(private authService: AuthService, private fb: FormBuilder, private db: AngularFirestore, private router: Router) { }

  ngOnInit() {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthDay: [null, Validators.required],
      parents: [[]]
    });

    this.authService.user$.subscribe(user => {
      this.currentUser = user;
      this.form.patchValue({ parents: [user.uid] });
    });
    this.users$ = this.db.collection<User>('users').valueChanges();
  }

  onSubmit() {
    const uid = this.db.createId();
    const child: Child = {
      uid,
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      birthDay: this.form.value.birthDay
    };
    this.db.collection<Child>('children').add(child);

    const promises = this.form.value.parents.map(parentId => {
      this.db.collection<User>('users').doc(parentId).update({
        children: firebase.firestore.FieldValue.arrayUnion(uid)
      });
    });

    Promise.all(promises).then(() => {
      this.router.navigate(['/dashboard']);
    });
  }
}
