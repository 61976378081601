import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Child, Event, EventType } from '../../interfaces';
import * as firebase from 'firebase';
import { Timestamp } from '@firebase/firestore-types';

@Component({
  selector: 'app-eat',
  templateUrl: './eat.component.html',
  styleUrls: ['./eat.component.css']
})
export class EatComponent implements OnInit {
  @Input('data') child: Child;
  @Output() accept: EventEmitter<Event> = new EventEmitter<Event>();

  private increment = 10;
  private initial = 80;
  amount: number;

  constructor() { }

  ngOnInit() {
    this.amount = this.initial;
  }

  onAddClick() {
    this.amount += this.increment;
  }
  onRemoveClick() {
    this.amount -= this.increment;
  }
  onDoneClick() {
    const event: Event = { type: EventType.Eat, date: firebase.firestore.FieldValue.serverTimestamp() as Timestamp, amount: this.amount };
    this.accept.emit(event);
    this.amount = this.initial;
  }
}
