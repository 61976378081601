import { Component, OnInit, Input } from '@angular/core';
import { Event, Child } from '../../interfaces';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  @Input('data') child: Child;
  @Input() events: Event[];

  constructor() { }

  ngOnInit() {
  }

}
