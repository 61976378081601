import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import * as firebase from 'firebase';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { switchMap, filter, debounceTime } from 'rxjs/operators';
import { Child, User } from './interfaces';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  user: User;
  children$: Observable<Child[]>;

  private userCollection: AngularFirestoreCollection<User>;

  constructor(private authService: AuthService, private db: AngularFirestore, private router: Router, private route: ActivatedRoute) {
    this.userCollection = db.collection<User>('users');
  }

  ngOnInit() {
    this.authService.user$.pipe(
      filter((authUser: firebase.User) => !!authUser),
      debounceTime(500), // workaround, since first response from firebase doesn't contain custom properties
      switchMap((authUser: firebase.User) => {
        return this.userCollection.doc(authUser.uid).valueChanges();
      }),
    ).subscribe((user: User) => {
      this.user = user;
      if (user.children && user.children.length > 0) {
        this.children$ = this.db.collection<Child>('children', ref => ref.where('uid', 'in', user.children)).valueChanges();
        this.router.navigate([user.children[0]], { relativeTo: this.route, replaceUrl: true });
      } else {
        this.router.navigate(['/add-child']);
      }
    });
  }

}
