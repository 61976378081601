import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { SleepComponent } from './components/sleep/sleep.component';
import { EatComponent } from './components/eat/eat.component';
import { CleanComponent } from './components/clean/clean.component';
import { BathComponent } from './components/bath/bath.component';
import { EventsComponent } from './components/events/events.component';
import { ChildTabComponent } from './components/child-tab/child-tab.component';
import { ChildFormModule } from '../child-form/child-form.module';
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [DashboardComponent, SleepComponent, EatComponent, CleanComponent, BathComponent, EventsComponent, ChildTabComponent],
    imports: [
        ChildFormModule,
        SharedModule,
        RouterModule
    ]
})
export class DashboardModule {
}
