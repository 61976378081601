import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ChildFormComponent } from './child-form.component';

@NgModule({
  declarations: [ChildFormComponent],
  imports: [
    SharedModule
  ],
  exports: [
    ChildFormComponent
  ]
})
export class ChildFormModule {
}
