import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {LoggedInGuard} from 'ngx-auth-firebaseui';
import {ChildFormComponent} from './child-form/child-form.component';
import {ChildTabComponent} from "./dashboard/components/child-tab/child-tab.component";

const routes: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [LoggedInGuard], children: [
      {path: 'new', component: ChildFormComponent, canActivate: [LoggedInGuard]},
      {path: ':id', component: ChildTabComponent, canActivate: [LoggedInGuard]}
    ]
  },
  {path: 'add-child', component: ChildFormComponent, canActivate: [LoggedInGuard]}
  // { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
