import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './modules/material.module';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [HeaderComponent, FooterComponent],
  exports: [
    HeaderComponent,
    FooterComponent,
    MaterialModule,
    FlexLayoutModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    NgxAuthFirebaseUIModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SharedModule {
}
