import {Component, OnInit, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Event, Child} from '../../interfaces';
import {ActivatedRoute} from "@angular/router";
import {switchMap} from "rxjs/operators";

@Component({
  selector: 'app-child-tab',
  templateUrl: './child-tab.component.html',
  styleUrls: ['./child-tab.component.css']
})
export class ChildTabComponent implements OnInit {
  child$: Observable<Child>;
  events$: Observable<Event[]>;
  private childDoc: AngularFirestoreDocument<Child>;


  constructor(private db: AngularFirestore, private route: ActivatedRoute) {
  }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.childDoc = this.db.collection('children').doc<Child>(params.get('id'));

      this.child$ = this.childDoc.valueChanges();
      this.events$ = this.childDoc.collection<Event>('events', ref =>
        ref.orderBy('date', 'desc')
          .limit(5)).valueChanges();
    });
  }

  onAccept(event: Event) {
    this.childDoc.collection<Event>('events').add(event);
  }
}
